/* eslint-disable global-require */
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import Layout from '@/components/layout';
import { ImportantInformation } from '@latitude/important-information';
import Section from '@latitude/section';
import { Promo } from '@latitude/promo';
import { Text } from '@latitude/text';
import { ListNumbered } from '@latitude/list-numbered';

import PageHeaderSection from '@/components/PageLayout/PageHeader';

import DegreesCardMicrositeHeader from './_28d-microsite-header';
import heroImage from './images/latitude-rewards-hero.webp';
import OtherFeaturesSection from './_other-features';
import DontHaveACardSection from './_dont-have-card';
import HaveAQuestionSection from '@/pages/travelpartners/_have-a-question';
import LatestOffersSection from './_latest-offers';
import imgPromoRewards from './images/promo-rewards.webp';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

function DegreesCardPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 10px !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`https://www.latitudefinancial.com.au/credit-cards/28-degrees/latitude-rewards/`}
          />
        </Helmet>
        <DegreesCardMicrositeHeader data={state.secondaryNavData} />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title="The travel card that loves shopping at home and away"
            subTitle={
              <>
                Your Latitude 28&deg; Global Platinum Mastercard credit card is
                more than just a travel credit card, with great online shopping
                and everyday benefits too!
              </>
            }
            pageImage={heroImage}
            pageImageAlt=""
          />
        )}

        <Section
          css={`
            && {
              background-color: #fff;
            }
          `}
        >
          <Promo
            title="Enjoy up to $120 worth of Latitude Rewards each year"
            description={
              <Text>
                Which you can redeem for e-gift cards from participating
                retailers when you spend $1,000 or more on eligible transactions
                in every statement period for 12 months<sup>1</sup>.
              </Text>
            }
            frameImage={
              <img src={imgPromoRewards} alt="Enjoy Latitude Rewards" />
            }
            verticalAlignContent
            frameAlignment="left"
            frameBorderColor="#0061EE"
          />
        </Section>

        <Section
          heading="How to redeem your Latitude Rewards"
          css={`
            && {
              background: #e2f2ff;
            }
          `}
        >
          <div css={{ maxWidth: '512px', margin: '0 auto' }}>
            <ListNumbered
              separator="none"
              viewStyle="compact"
              data={[
                '<span>Spend <strong>$1,000</strong> or more on eligible transactions within the statement period using your <strong>Latitude 28° Global Platinum Mastercard credit card</strong>.</span>',
                "<span>Download or open the <strong>Latitude App</strong> or log onto the <strong>Latitude Service Centre</strong>.<br /><a href='https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8' target='_blank' rel='noopener noreferrer' style='text-decoration: none; border: none;'><img alt='Apple Store' src='/images/badge-app-store.png' style='width: 150px; height: auto; padding: 5px;' /></a><a href='https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319' target='_blank' rel='noopener noreferrer' style='text-decoration: none; border: none;'><img alt='Google Play' src='/images/badge-google-play.png' style='width: 150px; height: auto; padding: 5px;' /></a></span>",
                "<span>Click on the <strong>'Benefits'</strong> tab.</span>",
                "<span>Click on the <strong>'Redeem now'</strong> button under the Latitude Rewards Program.</span>",
                '<span>Use your <strong>Latitude Rewards</strong> to redeem an e-gift card at your preferred participating retailer.</strong> You can also use your participating Latitude credit card to  purchase a portion or the total value of an e-gift card , allowing you to save your Latitude Rewards for a later redemption. Latitude Rewards expire 18 months from the date they have been issued.</span>',
                '<span>Follow the prompts to check out.</span>'
              ]}
            />
          </div>
        </Section>

        <LatestOffersSection anchor="latest-offers" />

        <OtherFeaturesSection
          anchor="dont-forget-other-features"
          heading="Don't forget all the other amazing features to enjoy!"
        />

        <DontHaveACardSection anchor="dont-have-card" />
        <HaveAQuestionSection anchor="have-question" />

        <ImportantInformation
          id="important-Info"
          data={{
            content: {
              importantInformationSectionOne: [
                "<sup id='note-1'>1</sup>&nbsp;Latitude 28&deg; Global Platinum Mastercard cardholders will be eligible for a $10 Latitude Reward every statement period which is redeemable for an e-gift card from participating retailers when they spend $1,000 or more on eligible transactions using their credit card within the statement period. Any purchases made by an additional cardholder will contribute towards the primary cardholder's spend threshold. Eligible transactions are based on the date posted and do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Shopper’s Protection premiums, interest and balance transfers into an account. The account must be open and not in default of the credit contract when the Latitude Rewards is issued. The Latitude Rewards will be issued by EonX Services Pty Ltd and will be available for redemption via the Latitude App or Latitude Service Centre within 30 days from the end of the statement period. The primary cardholder has up to 18 months to redeem from the date of issue. View full <a href='https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf' rel='noopener noreferrer' target='_blank'>terms and conditions</a>.",
                "<sup id='note-2'>2</sup>&nbsp;Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (“AIG”) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary Purchase Protection Insurance are the current holders of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. View full <a href='/purchaseterms/' rel='noopener noreferrer' target='_blank'>terms and conditions</a>."
              ],
              importantInformationSectionTwo: [
                "<sup id='note-3'>3</sup>&nbsp;E-Commerce Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (“AIG”) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary E-Commerce Purchase Protection Insurance are the current holder of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these e-commerce purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard E-Commerce Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. View full <a  href='/ecommerceterms/' rel='noopener noreferrer' target='_blank'>terms and conditions</a>.",
                "<sup id='note-4'>4</sup>&nbsp;The Flight Delay Pass gives free access to select airport lounges, alternatively cardholders can use their Flight Delay Pass to offset a dollar amount off the total bill at select dining options. This will vary based on location and affiliated LoungeKey Partners at the time. Please note that not all airports offer Flight Delay Pass and the selected airports may be restricted to certain terminals. Access to and use of the Flight Delay Pass is subject to the Terms of Use which are subject to change. Further details of the list of lounges that cardholders are eligible to visit and the specific conditions applicable to each lounge access (Conditions of Use) may also be accessed in the Terms of Use. Participating lounges and their Conditions of Use are subject to change.",
                "<sup id='note-5'>5</sup>&nbsp;$200 Latitude Reward is available to new and approved applicants who apply for a Latitude 28° Global Platinum Mastercard and is limited to one offer per applicant. To qualify, you must be approved and fulfil on the terms of the offer by spending $2,000 or more on eligible transactions each statement period for 3 months from the date of your approval. Any purchases made by an additional cardholder will contribute towards the primary cardholder's spend threshold. Eligible transactions are based on the date posted and do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Shopper's Protection premiums, interest and balance transfers into an account. The account must be open and not in default or the credit contract when the redeemable amount is issued. The $200 Latitude Reward will be issued by EonX Services Pty Ltd and will be available for redemption via the Latitude App or Latitude Service Centre within 30 days of meeting the spend criteria. The primary cardholder has up to 18 months to redeem from the date of issue. Offer ends 31 December 2024. Latitude may vary, extend or withdraw this offer at any time without notice.",
                'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.',
                'Apple, the Apple logo, Apple Pay, Apple Watch, Face ID, iPad, iPhone, Safari, and Touch ID are trademarks of Apple Inc., and App Store is a service mark of Apple Inc., registered in the U.S. and other countries.',
                'Google Pay, Android, the Google Pay Logo, Google Play and the Google Play logo are trademarks of Google LLC.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />

        {/* <Footer
          socialLinks={footerData.socialLinks}
          siteLinks={footerData.siteLinks}
          footerLinks={footerData.footerLinks}
          finePrint={footerData.finePrint}
        /> */}
      </main>
    </Layout>
  );
}

export default DegreesCardPage;
